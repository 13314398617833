import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Rewards from "../containers/old-rewards";

const IndexPage = () => {

	return (
		<Layout pageInfo={{ pageName: "rewards" }}>
			<ScrollUpButton ContainerClassName="custom-scroll-button" />
			<Seo title="Rewards" />

			<Row >
				<Col>
					<Container className="mt-5">
						<section id="rewards">
							<Rewards></Rewards>
						</section>
					</Container>
				</Col>
			</Row>


		</Layout>
	);
};

export default IndexPage;
